// stylelint-disable declaration-no-important

// Common values
@each $position in $positions {
	.position-#{$position} { position: $position !important; }
}

@each $name, $zidx in $zindexes {
	.zindex-#{$name} { z-index: $zidx !important; }
}

// Shorthand

.fixed-top {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: $zindex-fixed;

	&.fixed-backdrop {
		z-index: $zindex-fixed-backdrop
	}
}

.fixed-bottom {
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: $zindex-fixed;

	&.fixed-backdrop {
		z-index: $zindex-fixed-backdrop
	}
}

.fixed-center {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: $zindex-fixed;
	
	&.fixed-backdrop {
		z-index: $zindex-fixed-backdrop
	}
}

.sticky-top {
	@supports (position: sticky) {
		position: sticky;
		top: 0;
		z-index: $zindex-sticky;
	}
}
