// Contents
//
// Navbar
// Navbar brand
// Navbar nav
// Navbar text
// Navbar divider
// Responsive navbar
// Navbar position
// Navbar themes


// Navbar
//
// Provide a static navbar from which we expand to create full-width, fixed, and
// other navbar variations.

.navbar {
	position: relative;
	display: flex;
	flex-wrap: wrap; // allow us to do the line break for collapsing content
	align-items: center;
	justify-content: space-between; // space out brand from logo
	padding: $navbar-padding-y $navbar-padding-x;
	border-bottom: 1px solid transparent;

	// Because flex properties aren't inherited, we need to redeclare these first
	// few properties so that content nested within behave properly.
	> .container,
	> .container-fluid {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
	}
}


// Navbar brand
//
// Used for brand, project, or site names.

.navbar-brand {
	display: inline-block;
	padding: $navbar-brand-padding-y $navbar-brand-padding-x;
	margin-right: $navbar-padding-x;
	@include font-size($navbar-brand-font-size);
	font-weight: $navbar-brand-font-weight;
	line-height: inherit;
	white-space: nowrap;

	@include hover-focus {
		text-decoration: none;
	}
}


// Navbar nav
//
// Custom navbar navigation (doesn't require `.nav`, but does make use of `.nav-link`).

.navbar-nav {
	display: flex;
	flex-direction: column; // cannot use `inherit` to get the `.navbar`s value
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;

	.nav-divider {
		height: 1px;
		width: auto;
		background: transparent;
	}

	.dropdown-menu {
		position: static;
		float: none;
		border-width: 0;
		box-shadow: 0 1px 0 rgba($black,.125) inset;
	}
}


// Navbar text
//
//

.navbar-text {
	display: inline-block;
	padding-top: $nav-link-padding-y;
	padding-bottom: $nav-link-padding-y;
}


// Responsive navbar
//
// Custom styles for responsive collapsing and toggling of navbar contents.
// Powered by the collapse Bootstrap JavaScript plugin.

// When collapsed, prevent the toggleable navbar contents from appearing in
// the default flexbox row orientation. Requires the use of `flex-wrap: wrap`
// on the `.navbar` parent.
.navbar-collapse {
	flex-basis: 100%;
	flex-grow: 1;
	// For always expanded or extra full navbars, ensure content aligns itself
	// properly vertically. Can be easily overridden with flex utilities.
	align-items: center;
}

// Button for toggling the navbar when in its collapsed state
.navbar-toggler {
	position: relative;
	padding: $navbar-toggler-padding-y $navbar-toggler-padding-x;
	margin: $navbar-toggler-margin;
	@include font-size($navbar-toggler-font-size);
	line-height: 1;
	background-color: transparent; // remove default button style
	border: $border-width solid transparent; // remove default button style
	@include border-radius($navbar-toggler-border-radius);

	@include hover-focus {
		text-decoration: none;
	}
}

// Keep as a separate element so folks can easily override it with another icon
// or image file as needed.
.navbar-toggler-icon {
	display: inline-block;
	width: 1.5em;
	height: 1.5em;
	vertical-align: middle;
	content: "";
	background: no-repeat center center;
	background-size: 100% 100%;
}

.navbar-profile-icon {
	display: inline-block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: $navbar-profile-icon-size;
	height: $navbar-profile-icon-size;
	border-radius: 100%;
	transition: $transition-box-shadow;
	background: url(/auth/avatar/self) 50% 50%/100% 100% no-repeat $navbar-profile-icon-bg;
	box-shadow: 0 0 0 2px $navbar-light-profile-icon-bg-none;
}

// Generate series of `.navbar-expand-*` responsive classes for configuring
// where your navbar collapses.
.navbar-expand {
	@each $breakpoint in map-keys($grid-breakpoints) {
		$next: breakpoint-next($breakpoint, $grid-breakpoints);
		$infix: breakpoint-infix($next, $grid-breakpoints);

		&#{$infix} {
			@include media-breakpoint-down($breakpoint) {
				> .container,
				> .container-fluid {
					padding-right: 0;
					padding-left: 0;
				}
			}

			@include media-breakpoint-up($next) {
				flex-flow: row nowrap;
				justify-content: flex-start;

				.navbar-nav {
					flex-direction: row;

					.dropdown-menu {
						position: absolute;
						border-width: 0 1px 1px;
					}

					.nav-divider {
						height: auto;
						width: 1px;
					}
					.nav-divider:first-child {
						display: none;
					}

				}

				// For nesting containers, have to redeclare for alignment purposes
				> .container,
				> .container-fluid {
					flex-wrap: nowrap;
				}

				.navbar-collapse {
					display: flex; // stylelint-disable-line declaration-no-important

					// Changes flex-bases to auto because of an IE10 bug
					flex-basis: auto;
				}

				.navbar-toggler {
					display: none;
				}
			}
		}
	}
}


// Navbar themes
//
// Styles for switching between navbars with light or dark background.

// Dark links against a light background
.navbar-light {

	border-bottom-color: $navbar-light-border-color;

	.navbar-brand {
		color: $navbar-light-brand-color;

		@include hover-focus {
			color: $navbar-light-brand-hover-color;
		}
	}

	.navbar-nav {

		.navbar-profile-icon {
			background-color: $navbar-light-profile-icon-bg;
			box-shadow: 0 0 0 2px $navbar-light-profile-icon-bg-none;
		}

		.nav-link {
			color: $navbar-light-color;

			@include hover-focus {
				color: $navbar-light-hover-color;

				.navbar-profile-icon {
					box-shadow: 0 0 0 2px $navbar-light-profile-icon-bg-hover;
				}

			}

			&.disabled {
				color: $navbar-light-disabled-color;

				.navbar-profile-icon {
					opacity: 0.5;
				}

			}
		}

		.show > .nav-link,
		.active > .nav-link,
		.nav-link.show,
		.nav-link.active {
			color: $navbar-light-active-color;

			.navbar-profile-icon {

				.navbar-profile-icon {
					box-shadow: 0 0 0 2px $navbar-light-profile-icon-bg-active;
				}

			}

		}

		.nav-divider {
			background: $navbar-light-border-color;
		}

		.dropdown-menu {
			box-shadow: 0 1px 0 $dropdown-light-box-shadow-color inset;
		}

		&.navbar-pills:not(.navbar-not-pills) {

			.nav-link {
				color: $navbar-light-active-color;
				transition: $transition-background;

				@include hover-focus {
					background-color: $navbar-light-hover-bg;
				}

				&.disabled {
					color: $navbar-light-disabled-color;
				}
			}

			.show > .nav-link,
			.active > .nav-link,
			.nav-link.show,
			.nav-link.active {
				background-color: $navbar-light-active-bg;
			}

		}

	}

	.navbar-toggler {
		color: $navbar-light-color;
	}

	.navbar-toggler-icon {
		background-image: $navbar-light-toggler-icon-bg;
	}

	.navbar-text {
		color: $navbar-light-color;
		a {
			color: $navbar-light-active-color;

			@include hover-focus {
				color: $navbar-light-active-color;
			}
		}
	}
}

// White links against a dark background
.navbar-dark {

	border-bottom-color: $navbar-dark-border-color;

	.navbar-brand {
		color: $navbar-dark-brand-color;

		@include hover-focus {
			color: $navbar-dark-brand-hover-color;
		}
	}

	.navbar-nav {

		.navbar-profile-icon {
			background-color: $navbar-dark-profile-icon-bg;
			box-shadow: 0 0 0 2px $navbar-dark-profile-icon-bg-none;
		}

		.nav-link {
			color: $navbar-dark-color;

			@include hover-focus {
				color: $navbar-dark-hover-color;

				.navbar-profile-icon {
					box-shadow: 0 0 0 2px $navbar-dark-profile-icon-bg-hover;
				}

			}

			&.disabled {
				color: $navbar-dark-disabled-color;

				.navbar-profile-icon {
					opacity: 0.5;
				}

			}
		}

		.nav-divider {
			background: $navbar-dark-border-color;
		}

		.show > .nav-link,
		.active > .nav-link,
		.nav-link.show,
		.nav-link.active {
			color: $navbar-dark-active-color;

			.navbar-profile-icon {
				box-shadow: 0 0 0 2px $navbar-dark-profile-icon-bg-active;
			}
		}

		.dropdown-menu {
			box-shadow: 0 1px 0 $dropdown-dark-box-shadow-color inset;
		}

		&.navbar-pills:not(.navbar-not-pills) {

			.nav-link {
				color: $navbar-dark-active-color;
				transition: $transition-background;

				@include hover-focus {
					background-color: $navbar-dark-hover-bg;
				}

				&.disabled {
					color: $navbar-dark-disabled-color;
				}
			}

			.show > .nav-link,
			.active > .nav-link,
			.nav-link.show,
			.nav-link.active {
				background-color: $navbar-dark-active-bg;
			}

		}

	}

	.navbar-toggler {
		color: $navbar-dark-color;
	}

	.navbar-toggler-icon {
		background-image: $navbar-dark-toggler-icon-bg;
	}

	.navbar-text {
		color: $navbar-dark-color;
		a {
			color: $navbar-dark-active-color;

			@include hover-focus {
				color: $navbar-dark-active-color;
			}
		}
	}
}

.navbar-backdrop {
	width: 100%;
	height: 100%;
	background: rgba($navbar-backdrop-bg, $navbar-backdrop-opacity);
	opacity: 0;
	transition: $navbar-backdrop-transition;
	pointer-events: none;

	&.show {
		opacity: 1;
		pointer-events: auto;
	}
}

